import axios, { saveCoustomBg } from "../lib/axios";
//发送短信
export const sendCode = (p: any) => axios.post("/beemobile/index/sendCode", p);
//手机号登录
export const login = (p: any) => axios.post("/beemobile/index/login", p);
//微信支付宝登录
export const appLogin = (p: any) => axios.post("/beemobile/index/appLogin", p);
export const getStore = async (p: any) => {
  const data = await axios.post("/beemobile/store/getStore", p);
  saveCoustomBg(data.data);
  return data;
};
export const getRoomList = (p: any) =>
  axios.post("/beemobile/store/getRoomList", p);
export const getRoomDetails = (p: any) =>
  axios.post("/beemobile/store/getRoomDetails", p);
export const CreateOrder = (p: any) =>
  axios.post("/beemobile/Order/CreateOrder", p);
export const orderDetails = (p: any) =>
  axios.post("/beemobile/Order/orderDetails", p);
export const subOrderMessage = (p: any) =>
  axios.post("/beemobile/order/subOrderMessage", p);
export const orderList = (p: any) =>
  axios.post("/beemobile/order/orderList", p);
export const refundOrder = (p: any) =>
  axios.post("/beemobile/order/refundOrder", p);
export const cancelOrder = (p: any) =>
  axios.post("/beemobile/order/cancelOrder", p);
export const evenOrder = (p: any) =>
  axios.post("/beemobile/Order/evenOrder", p);
export const room_details = async (p: any) => {
  const data = await axios.post("/beemobile/room/room_details", p);
  saveCoustomBg(data.data);
  setPramaryColor(data.data.color);
  return data;
};

export const getUserInfo = (p: any) =>
  axios.post("/beemobile/user/getUserInfo", p);
export const getOrderUser = (p: any) =>
  axios.post("/beemobile/Invite/getOrderUser", p);
export const getFriendInvitation = (p: any) =>
  axios.post("/beemobile/Invite/getFriendInvitation", p);
export const delInviteUser = (p: any) =>
  axios.post("/beemobile/Invite/delInviteUser", p);
export const addInviteFriend = (p: any) =>
  axios.post("/beemobile/Invite/addInviteFriend", p);
export const jweixin = (p: any) => axios.post(`/beemobile/Invite/jweixin`, p);
export const getKey = (p: any) => axios.post("/beemobile/Order/getKey", p);

import { setPramaryColor } from "@/assets/style/main";
export const h5color = async (shopid: string | number) => {
  const res = await axios.post("/beemobile/index/h5color", {
    store_id: shopid,
  });
  setPramaryColor(res.data.color);
  return res;
};

export const getCabinetList = (p: any) =>
  axios.post("/beemobile/Cabinet/getCabinetList", p);
export const getCabinetInfo = async (p: any) => {
  const data = await axios.post("/beemobile/Cabinet/getCabinetInfo", p);
  console.log(data);
  saveCoustomBg(data.data);
  return data;
};
export const creatCabeOrder = (p: any) =>
  axios.post("/beemobile/CabinetOrder/evenOrder", p);
export const caborderDetails = (p: any) =>
  axios.post("/beemobile/CabinetOrder/orderDetails", p);
export const orderFinish = (p: any) =>
  axios.post("/beemobile/CabinetOrder/orderFinish", p);
export const caborderList = (p: any) =>
  axios.post("/beemobile/cabinetOrder/orderList", p);
export const cabCreateOrder = (p: any) =>
  axios.post("/beemobile/CabinetOrder/CreateOrder", p);
export const orderListNow = (p: any) =>
  axios.post("/beemobile/CabinetOrder/orderListNow", p);
export const orderStatus = (p: any) =>
  axios.post("/beemobile/CabinetOrder/orderStatus", p);
export const getAdmin = (p: any) => axios.post("/beemobile/User/getAdmin", p);
export const orderPrice = (p: any) =>
  axios.post("/beemobile/CabinetOrder/orderPrice", p);
export const orderEnd = (p: any) =>
  axios.post("/beemobile/CabinetOrder/orderEnd", p);
export const getcabKey = (p: any) =>
  axios.post("/beemobile/CabinetOrder/getKey", p);
export const orderBeEnd = (p: any) =>
  axios.post("/beemobile/CabinetOrder/orderBeEnd", p);
export const CabinetTakeCheck = (p: any) =>
  axios.post("/beemobile/CabinetOrder/CabinetTakeCheck", p);
export const takeKey = (p: any) => axios.post("/beemobile/Cabinet/takeKey", p);
export const checkOrderTime = (p: any) =>
  axios.post("/beemobile/CabinetOrder/checkOrderTime", p);
export const orderBackOne = (p: any) =>
  axios.post("/beemobile/CabinetOrder/orderBackOne", p);
export const openDoors = (p: any) =>
  axios.post("/beemobile/order/openDoors", p);
export const getInvoiceOrder = (p: any) =>
  axios.post("/beemobile/Invoice/getInvoiceOrder", p);
export const applyInvoice = (p: any) =>
  axios.post("/beemobile/Invoice/applyInvoice", p);
export const getInvoiceApplyInfo = (p: any) =>
  axios.post("/beemobile/Invoice/getInvoiceApplyInfo", p);
export const getInvoiceTitle = (p: any) =>
  axios.post("/beemobile/Invoice/getInvoiceTitle", p);
export const getInvoiceTitleOne = (p: any) =>
  axios.post("/beemobile/Invoice/getInvoiceTitleOne", p);
export const editClientInvoice = (p: any) =>
  axios.post("/beemobile/Invoice/editClientInvoice", p);
export const addClientInvoice = (p: any) =>
  axios.post("/beemobile/Invoice/addClientInvoice", p);
export const getInvoiceOrderInfo = (p: any) =>
  axios.post("/beemobile/Invoice/getInvoiceOrderInfo", p);
export const acceptInvoice = (p: any) =>
  axios.post("/beemobile/Invoice/acceptInvoice", p);
export const getInvoiceHistory = (p: any) =>
  axios.post("/beemobile/Invoice/applyList", p);
export const sendEmail = (p: any) =>
  axios.post("/beemobile/Invoice/sendEmail", p);
export const reapply = (p: any) => axios.post("/beemobile/Invoice/reapply", p);
export const getOrderRoom = (p: any) =>
  axios.post("/beemobile/room/getOrderRoom", p);
export const getRenew = (p: any) => axios.post("/beemobile/room/getRenew", p);
export const CreateRenewOrder = (p: any) =>
  axios.post("/beemobile/Order/CreateRenewOrder", p);
export const orderIsOpen = (p: any) =>
  axios.post("/beemobile/order/orderIsOpen", p);
export const changeRoom = (p: any) =>
  axios.post("/beemobile/order/changeRoom", p);
export const cabinetOpenStatus = (p: any) =>
  axios.post("/beemobile/CabinetOrder/cabinetOpenStatus", p);
export const getSmallKey = (p: any) =>
  axios.post("/beemobile/CabinetOrder/getSmallKey", p);
export const getAdvanceUse = (p: any) =>
  axios.post("/beemobile/room/getAdvanceUse", p);
export const AdvanceUseIsOpen = (p: any) =>
  axios.post("/beemobile/room/AdvanceUseIsOpen", p);
export const shareOrder = (p: any) =>
  axios.post("/beemobile/Invite/shareOrder", p);
export const countPrice = (p: any) =>
  axios.post("/beemobile/store/countPrice", p);
export const getRoomIsOrder = (p: any) =>
  axios.post("/beemobile/room/getRoomIsOrder", p);
export const getRoomInUse = (p: any) =>
  axios.post("/beemobile/room/getRoomInUse", p);
export const memberIsPwd = (p: any) =>
  axios.post("/beemobile/user/memberIsPwd", p);
export const setMemberPwd = (p: any) =>
  axios.post("/beemobile/user/setMemberPwd", p);
export const memberFreePwd = (p: any) =>
  axios.post("/beemobile/user/memberFreePwd", p);
export const setMemberFreePwd = (p: any) =>
  axios.post("/beemobile/user/setMemberFreePwd", p);
export const sendCode2 = (p: any) =>
  axios.post("/beemobile/user/checkSendCode", p);
export const checkMobile = (p: any) =>
  axios.post("/beemobile/user/checkMobile", p);
export const getMemberBalance = (p: any) =>
  axios.post("/beemobile/user/getMemberBalance", p);
export const memberOrder = (p: any) =>
  axios.post("/beemobile/user/memberOrder", p);
export const cashier = (p: any) => axios.post("/beemobile/pay/cashier", p);
export const checkMemberPwd = (p: any) =>
  axios.post("/beemobile/user/checkMemberPwd", p);
export const evenCountPrice = (p: any) =>
  axios.post("/beemobile/store/evenCountPrice", p);
export const dedBalance = (p: any) =>
  axios.post("/beemobile/pay/dedBalance", p);
export const changeRoomClean = (p: any) =>
  axios.post("/beemobile/order/changeRoomClean", p);
export const renewPrice = (p: any) =>
  axios.post("/beemobile/Order/renewPrice", p);
export const exchangeCoupons = (p: any) =>
  axios.post("/beemobile/coupon/exchangeCoupons", p);
export const getUserCoupons = (p: any) =>
  axios.post("/beemobile/coupon/getUserCoupons", p);
export const subscribeService = (p: any) =>
  axios.post("/beemobile/store/subscribeService", p);
///export const  = (p: any) => axios.post('/', p)
//export const  = (p: any) => axios.post('/', p)
//export const  = (p: any) => axios.post('/', p)
//export const  = (p: any) => axios.post('/', p)
//export const  = (p: any) => axios.post('/', p)
//export const  = (p: any) => axios.post('/', p)
//export const  = (p: any) => axios.post('/', p)

//export const  = (p: any) => axios.post('/', p)
//export const  = (p: any) => axios.post('/', p)
//export const  = (p: any) => axios.post('/', p)
